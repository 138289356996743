import React, { Component } from 'react'

class WidgetLoader extends Component {
  widgetKey = null

  initWidget = () => {
    const { feedKey, articleKey, contestKey } = this.props
    const p = {
      fk: feedKey,
      ak: articleKey,
      cid: contestKey,
      bid: `choicely-widget-${feedKey || articleKey || contestKey}`,
      aul: 'facebook,google'
    }

    if (window.ChoicelyWidget) {
      window.ChoicelyWidget.i(p)
    }
  }
  
  componentDidMount() {
    this.initWidget()
  }

  render() {
    const { feedKey, articleKey, contestKey } = this.props
    const widgetKey = feedKey || articleKey || contestKey
    return <div className="pv3" id={`choicely-widget-${widgetKey}`} />
  }
}

export default WidgetLoader
