import React, { Fragment, Component } from 'react'
import classNames from 'classnames'

import Layout from '@/layout'
import SEO from '@/seo'
import Container from '@/container'
import WidgetLoader from '@/widget-loader'
import { loadScript } from 'utils/common'

const Widget = ({ feedKey, articleKey, contestKey, isActive }) => (
  <div
    className={classNames({
      dn: !isActive,
      db: isActive
    })}
  >
    <WidgetLoader feedKey={feedKey} articleKey={articleKey} contestKey={contestKey} />
  </div>
)

class HomePage extends Component {
  state = { scriptLoaded: false, hash: 'home' }

  componentDidMount() {
    loadScript(
      `choicely-widget`,
      `https://testcdn.choicely.com/widget-stage/widget.js?t=${Date.now()}`,
      () => this.setState({ scriptLoaded: true })
    )

    this.setHash(window.location.hash.replace('#', '') || 'home')
  }

  setHash = hash => {
    this.setState({ hash })
    window.location.hash = `#${hash}`
  }

  render() {
    const { hash } = this.state

    const seoTitle = hash.charAt(0).toUpperCase() + hash.slice(1)

    if (!this.state.scriptLoaded) {
      return null
    }

    return (
      <Fragment>
        <SEO title={seoTitle} />
        <Layout setHash={this.setHash} hash={hash}>
          <Container>
            <Widget
              feedKey="ag1zfmxvdmVudGVkYXBwchELEgRGZWVkGICAwIPPz4AKDA"
              isActive={hash === 'home'}
            />
            <Widget
              contestKey="b4516a45-b57c-11ea-89cc-29552ad9816c"
              isActive={hash === 'vote'}
            />
            <Widget
              feedKey="ag1zfmxvdmVudGVkYXBwchELEgRGZWVkGICAwKPowYsJDA"
              isActive={hash === 'shop'}
            />
            <Widget
              feedKey="ag1zfmxvdmVudGVkYXBwchELEgRGZWVkGICAwKOnlZ8KDA"
              isActive={hash === 'extra'}
            />
          </Container>
        </Layout>
      </Fragment>
    )
  }
}

export default HomePage
