import React from 'react'

import Container from '@/container'

import HeaderLogo from './header-logo'
import HeaderTabs from './header-tabs'
import HeaderDropdown from './header-dropdown'

import './styles.css'

const Header = ({ setHash, hash }) => (
  <header className="bb b--moon-gray black z-99999 bg-accent-color">
    <Container fullWidth>
      <div className="flex flex-wrap flex-nowrap-m items-center justify-between">
        <HeaderLogo />
        <HeaderTabs setHash={setHash} hash={hash} />
        <HeaderDropdown setHash={setHash} hash={hash} />
      </div>
    </Container>
  </header>
)

export default Header
