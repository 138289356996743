import React from 'react'
import Helmet from 'react-helmet'

import appleTouchIcon from './apple-touch-icon.png'
import favicon32x32 from './favicon-32x32.png'
import chrome192x192 from './android-chrome-192x192.png'
import favicon16x16 from './favicon-16x16.png'
import favicon from './favicon.ico'

const Favicons = () => (
  <Helmet>
    <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
    <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
    <link rel="icon" type="image/png" sizes="192x192" href={chrome192x192} />
    <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
    <link rel="shortcut icon" href={favicon} />
    <meta name="msapplication-TileColor" content="#ffffff" />
  </Helmet>
)

export default Favicons
