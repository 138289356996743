export function loadScript(id, src, callback = null, asyncValue = false) {
  return new Promise(resolve => {
    let result = false
    const script = document.createElement('script')
    script.async = asyncValue
    script.id = id
    script.type = 'text/javascript'
    script.src = src
    script.onload = script.onreadystatechange = function() {
      if (!result && (!this.readyState || this.readyState === 'complete')) {
        result = true
        if (typeof callback === 'function') {
          callback()
        }
        resolve()
      }
    }
    if (!document.getElementById(id)) {
      document.getElementsByTagName('head')[0].appendChild(script)
    } else {
      if (typeof callback === 'function') {
        callback()
      }
      resolve()
    }
  })
}
