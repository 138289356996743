import React, { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'

import './styles.css'

const ListItem = ({ text, onClick, isActive }) => (
  <li className="bb b--moon-gray last-bw0 mh2">
    <button
      type="button"
      onClick={onClick}
      className={classNames({
        'bg-transparent bw0 w-100 pa3 f11 dark-gray': true,
        'db tl no-underline pointer': true,
        'hover-accent-color': !isActive,
        'bg-accent-color white': isActive
      })}
    >
      {text}
    </button>
  </li>
)

const HeaderDropdown = ({ setHash, hash }) => {
  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside)

    return () => {
      document.removeEventListener('mousedown', onClickOutside)
    }
  })

  const [active, setActive] = useState(false)
  const menu = useRef(null)

  const onClickOutside = ({ target }) => {
    if (menu.current && !menu.current.contains(target)) {
      onMenuClose()
    }
  }

  const toggleMenu = () => setActive(!active)
  const onMenuClose = () => setActive(false)

  return (
    <div className="relative order-2 order-3-m w-50 w-20-m">
      <div className="tr">
        <button
          type="button"
          onClick={toggleMenu}
          className="pa2 bg-transparent bw0 pointer white dim"
        >
          <svg width="17" height="13" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17 12v1H0v-1h17zm0-6v1H0V6h17zm0-6v1H0V0h17z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
        </button>
      </div>

      <ul
        className={classNames({
          'hidden o-0': !active,
          'bg-white absolute top-100 right-0 mt3 mr--3': true,
          'pa3 w288 ba b--moon-gray tl': true,
          'dd-menu-arrow': true
        })}
        ref={menu}
      >
        <ListItem
          isActive={hash === 'home'}
          text="Home"
          onClick={() => setHash('home')}
        />
        <ListItem
          isActive={hash === 'vote'}
          text="Vote"
          onClick={() => setHash('vote')}
        />
        <ListItem
          isActive={hash === 'shop'}
          text="Shop"
          onClick={() => setHash('shop')}
        />
        <ListItem
          isActive={hash === 'extra'}
          text="Extra"
          onClick={() => setHash('extra')}
        />
      </ul>
    </div>
  )
}

export default HeaderDropdown
