import React from 'react'

import logo from './logo.png'

const HeaderLogo = () => (
  <div className="w-50 w-20-m pv3 pv0-m">
    <a href="/" className="db current-color no-underline flex-shrink-0 order-1">
      <img src={logo} alt="" width="119" />
    </a>
  </div>
)

export default HeaderLogo
